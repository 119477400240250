import { render, staticRenderFns } from "./MyKiosk.vue?vue&type=template&id=01934b8f&scoped=true&class=my-kiosk&"
import script from "./MyKiosk.vue?vue&type=script&lang=js&"
export * from "./MyKiosk.vue?vue&type=script&lang=js&"
import style0 from "./MyKiosk.vue?vue&type=style&index=0&id=01934b8f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01934b8f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VNavigationDrawer,VRow,VSheet,VSwitch})
